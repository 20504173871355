import ProfileMenu from '../Common/ProfileMenu/ProfileMenu.vue'
import SupportCard from '../Common/SupportCard/SupportCard.vue'
import MyContactData from './MyContactData/MyContactData.vue'
import ContactDataService from '@/services/contact-data.service.js'
import { ContactData } from '@/models/account/ContactData.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'ContactData',
  components: {
    HeaderTopDashboard,
    ProfileMenu,
    SupportCard,
    MyContactData,
  },
  data: function () {
    return {
      tab: [],
      principalData: { },
      financeData: { },
      accountManagerData: { },
      priceData: { },
      companyInformation: null,
      username: null,
      lang: null,
      timezone: null,
      ready: false,
      show: false,
      profileData: null,
      emails: [],
    }
  },
  watch: {
    'companyInformation.emails': function (emails) {
      if (emails.length > 5) {
        this.$nextTick(() => this.companyInformation.emails.pop())
      }
    },
  },
  created () {
    this.setPrincipalData()
  },

  methods: {
    setPrincipalData () {
      ContactDataService.getData().then((contactData) => {
        this.principalData = contactData.supportInformation ? contactData.supportInformation : new ContactData()
        this.financeData = contactData.infoFinanceManager ? contactData.infoFinanceManager : new ContactData()
        this.accountManagerData = contactData.accountManager ? contactData.accountManager : new ContactData()
        this.priceData = contactData.priceChangeContact ? contactData.priceChangeContact : new ContactData()
        this.ready = true
      })
    },
    validateEmails () {
      const last = this.companyInformation.emails.length - 1
      const lastEmail = this.companyInformation.emails[last]
      if (!this.validateEmail(lastEmail)) {
        this.companyInformation.emails.splice(last, 1)
      }
    },
  },
}
