
import EventBus from '@/util/EventBus.js'
import ContactData from '@/services/contact-data.service.js'

export default {
  props: ['contact', 'attrsMi', 'typeContact'],
  name: 'MyContactData',
  components: {},
  data: function () {
    return {
      data: {},
      showEditForm: false,
      showErrorEmail: false,
      errorEmail: '',
    }
  },
  methods: {
    setAvailableEdit () {
      this.showEditForm = true
    },
    saveData () {
      if (this.validateEmail(this.data.email)) {
        this.showErrorEmail = false
        const accountData = { contact: this.data, typeContact: this.typeContact }
        ContactData.saveData(accountData).then((response) => {
          EventBus.$emit('showAlert', 'success', this.$t('Se han actualizado los datos de contacto').toString() + '.')
          this.showEditForm = false
        })
          .catch((response) => {
            EventBus.$emit('showAlert', 'danger', this.$t('No se han podido actualizar los datos de contacto'.toString() + '.'))
          })
      } else {
        this.showErrorEmail = true
      }
    },
    validateEmail (email) {
      this.errorEmail = ''
      if (this.typeContact === 'financeData') {
        if (!email.length) {
          this.errorEmail = this.$t('Al menos un email es requerido')
          return false
        }
      }
      var valid = true
      email.forEach(mail => {
        // eslint-disable-next-line no-useless-escape
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))) {
          valid = false
          this.errorEmail = this.$t('Al menos uno de los emails es invalido')
        }
      })
      return valid
    },
    maxFiveObjects () {
      if (this.data.email.length > 5) {
        this.$nextTick(() => this.data.email.pop())
      }
      if (this.data.MI.length > 5) {
        this.$nextTick(() => this.data.MI.pop())
      }
    },
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.data = this.contact
    this.data.email = this.data.email ? this.data.email.filter(email => email?.length) : []
    this.data.MI = this.attrsMi.length !== 0 ? this.attrsMi.split(';') : []
  },
  mounted () {
  },
}
