import { Service } from './service'

const PATH = 'account'

class ContactData extends Service {
  getData (params = {}) {
    return this.get(PATH + '/getaccountdata', params)
  }

  saveData (params = {}) {
    const contact = {
      email: params.contact.email.join(';'),
      MI: params.contact.MI.join(';'),
      name: params.contact.name,
      phonenumber: params.contact.phonenumber,
      mobile: params.contact.mobile,
    }

    return this.post(PATH + '/saveaccountdata', { contact: contact, typeContact: params.typeContact })
  }
}

export default new ContactData()
