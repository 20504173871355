var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showEditForm)?_c('v-row',[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Nombre'),"readonly":"","filled":"","color":"light-blue darken-2"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Teléfono'),"readonly":"","filled":"","color":"light-blue darken-2"},model:{value:(_vm.data.phonenumber),callback:function ($$v) {_vm.$set(_vm.data, "phonenumber", $$v)},expression:"data.phonenumber"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Móvil'),"readonly":"","filled":"","color":"light-blue darken-2"},model:{value:(_vm.data.mobile),callback:function ($$v) {_vm.$set(_vm.data, "mobile", $$v)},expression:"data.mobile"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Email","readonly":"","filled":"","color":"light-blue darken-2"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"text-white text-transform-none px-5 fw300 float-right",attrs:{"color":"light-green darken-2","elevation":"0","rounded":""},on:{"click":_vm.setAvailableEdit}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-edit ")]),_vm._v(" "+_vm._s(_vm.$t("Modificar"))+" ")],1)],1)],1):_vm._e(),(_vm.showEditForm)?_c('div',[_c('validation-observer',{ref:"observerContactData",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"formContactData"},[_c('v-row',[_c('v-col',{staticClass:"mt-n3"},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":{
                max: 60,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Nombre'),"filled":"","error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Teléfono'),"rules":{
                max: 15,
                regex: /^\d+$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('Teléfono'),"filled":""},model:{value:(_vm.data.phonenumber),callback:function ($$v) {_vm.$set(_vm.data, "phonenumber", $$v)},expression:"data.phonenumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Móvil'),"rules":{
                max: 15,
                regex: /^\d+$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Móvil'),"filled":"","error-messages":errors},model:{value:(_vm.data.mobile),callback:function ($$v) {_vm.$set(_vm.data, "mobile", $$v)},expression:"data.mobile"}})]}}],null,true)}),_c('v-combobox',{attrs:{"hide-selected":"","hint":_vm.$t('Hasta 5 Emails'),"label":"Emails","multiple":"","persistent-hint":"","small-chips":""},on:{"change":function($event){return _vm.maxFiveObjects()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var parent = ref.parent;
              var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1)]}}],null,true),model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),(_vm.showErrorEmail)?_c('label',{staticStyle:{"color":"red"},attrs:{"dense":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.errorEmail)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.showEditForm)?_c('v-btn',{staticClass:"text-white",attrs:{"color":"blue"},on:{"click":_vm.setAvailableEdit}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-edit ")]),_vm._v(" "+_vm._s(_vm.$t("Modificar información de la empresa"))+" ")],1):_vm._e(),(_vm.showEditForm)?_c('v-btn',{staticClass:"text-white",attrs:{"color":"blue","disabled":invalid},on:{"click":_vm.saveData}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")],1):_vm._e()],1)],1)],1)]}}],null,false,3579116700)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }